import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
// import ButtonControlled from "app/components/Button";
// import React from "react";
import { LoadingButton } from '@mui/lab';
// import contentService from "services/api/contentService";
// import { ContentPayload } from "types/Content";
// import toast from "react-hot-toast";
// import Map from 'react-map-gl';
// import Map, { Marker, NavigationControl } from 'react-map-gl';
import type { MarkerDragEvent, LngLat } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomizedInputsStyled from 'app/components/BootstrapInput';
import { numberRegex } from 'utils/helpers/regex';
import { mapboxToken } from 'utils/config';
import locationService from 'services/api/locationService';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import toast from 'react-hot-toast';

import { LocationPayload } from 'types/Location';

import ControlPanel from './components/ControlPanel';

import Pin from './components/Pin';
const initialViewState = {
  latitude: 40,
  longitude: -100,
  zoom: 20.5,
};

interface DataProps {
  latitude: string;
  longitude: string;
  radius: string;
  id?: string;
  zoom: number;
}

interface Marker {
  latitude: string;
  longitude: string;
  // zoom: number;
}


const Location = () => {
  const google1 = window.google;
  // console.log(google1, 'google')
  // const [mapref, setMapRef] = React.useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAC599y9-CDIAy1fKHdp_AEhSdOs9HjCA4"
  })
  // console.log(isLoaded, 'isLoaded')
  // const [map, setMap] = React.useState<any | null>(null);
  // console.log(map?.getCenter()?.lat(), 'check 1111');
  const [loading, setLoading] = useState<boolean>(false);
  // const [radius, setRadius] = useState<string>('0');
  const [data, setData] = useState<DataProps>({
    latitude: '16.098900',
    longitude: '108.244690',
    radius: '',
    id: '',
    zoom: 15,
  });
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response: any = await locationService.get();
    const res: any[] = response.data;
    if (res.length > 0) {
      setData({
        latitude: res[0].latitude,
        id: res[0].id,
        longitude: res[0].longitude,
        radius: res[0].radius,
        zoom: res[0].zoom,
      });
    }
  };

  const handleChange = (v: React.ChangeEvent<HTMLInputElement>) => {
    if (numberRegex.test(v.target.value)) {
      setData({ ...data, radius: v.target.value });
    }
  };

  const handleSubmit = async () => {
    // setLoading(true);
    const payloads: LocationPayload = {
      latitude: data.latitude ? data.latitude.toString() : '',
      longitude: data.longitude ? data.longitude.toString() : '',
      radius: data.radius,
      zoom: +data.zoom,
    };
    console.log(payloads, 'payloads')
    if (data.id) {
      payloads.uuid = data.id;
    }
    try {
      setLoading(false);
      const res = await locationService.create(payloads);
      // console.log(res, '------ res')
      if (res.status === 200) {
        toast.success(
          `${!data.id ? 'Create ' : 'Update'} content successfully`,
          {
            duration: 2000,
          },
        );
        setData({ ...data, ...payloads });
      }
    } catch (error) {
      setLoading(false);
      toast.error('Create content error.', {
        duration: 2000,
      });
    }
  };

  const onMarkerDragEnd = (coord: any) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setData({
      ...data,
      latitude: lat,
      longitude: lng
    })
  }

  return (
    <Box sx={{ padding: '0 64px' }}>
      <Box
        sx={{
          height: 'calc(100vh - 320px)',
          position: 'relative',
        }}
      >
        {google1 && isLoaded && <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%'
          }}
          center={{
            lat: +data.latitude,
            lng: +data.longitude
          }}
          zoom={15}
        >
          <MarkerF
            draggable
            onDragEnd={onMarkerDragEnd}
            position={{
              lat: +data?.latitude,
              lng: +data?.longitude
            }}
          >
           
          </MarkerF>
        </GoogleMap>}
      </Box>
      <Box mt={2} sx={{ display: 'flex', alignItems: 'end' }}>
        <Box sx={{ width: '250px' }}>
          <CustomizedInputsStyled
            lable="Radius"
            placeholder="Radius"
            handleChange={handleChange}
            value={data.radius}
            endAdornment="m"
            // helperText={errors?.physical_width?.message as string}
          />
        </Box>
        <Box sx={{ ml: 2 }}>
          <LoadingButton
            color="secondary"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
            // loadingPosition="start"
            sx={{
              borderRadius: '10px',
              background: '#FF66A3',
              color: '#FFF',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
              '&:hover': {
                background: '#FF66A3',
              },
            }}
            // startIcon={<SaveIcon />}
            variant="contained"
          >
            SAVE
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Location;
