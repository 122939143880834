import { Theme } from '@mui/material';

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            '&:hover': { backgroundColor: theme.palette.action.hover },
          },
        },
        {
          props: { color: 'inherit' },
          style: {
            '&:hover': { backgroundColor: theme.palette.action.hover },
          },
        },
      ],

      styleOverrides: {
        root: {
          paddingTop: 10,
          paddingBottom: 10,
          minWidth: 44,
          fontSize: '0.875rem',
          color: theme.palette.primary.main,
          borderRadius: '10px 0',
          fontWeight: 500,
          textTransform: 'initial',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
      },
    },
  };
}
