import { useRoutes, Navigate } from 'react-router-dom';
import Auth from 'app/pages/Auth';
import NotFound from 'app/pages/NotFound';
import { isAuthenticated } from 'utils/auth';
import { useLocation } from 'react-router';

import DashboardLayout from 'app/Layouts/DashboardLayout';
import LogoOnlyLayout from 'app/Layouts/LogoOnlyLayout';
import AssetManagement from 'app/pages/AssetManagement';
import Content from 'app/pages/Content';
import Location from 'app/pages/Location';

import path from './path';

export default function Router() {
  const location = useLocation();

  return useRoutes([
    {
      path: path.root,
      element: isAuthenticated() ? (
        <DashboardLayout />
      ) : (
        <Navigate to={path.login} state={{ from: location }} />
      ),
      children: [
        {
          path: path.assets,
          children: [
            {
              path: path.assetManagement,
              element: <AssetManagement />,
            },
          ],
        },
        {
          element: <Content />,
          path: path.content,
        },
        {
          element: <Location />,
          path: path.location,
        },
      ],
    },
    {
      element: <LogoOnlyLayout />,
      children: [
        {
          path: path.login,
          element: isAuthenticated() ? <Navigate to={path.root} /> : <Auth />,
        },
      ],
    },
    { path: path.all, element: <Navigate to={path.notFound} replace /> },
  ]);
}
