import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled, Theme } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
import LogoArMuseum from 'assets/logo/logo_sm.svg';
import { Icon } from '@iconify/react';

import path from 'app/routes/path';

import { GPSidebarMenu } from 'types';

import Scrollbar from '../Scrollbar';
import NavSection from '../NavSection';
import { MHidden } from '../@material-extend';

import assetManagementIcon from '../../../assets/logo/assets/asset-management.svg';
import contentManagementIcon from '../../../assets/logo/assets/content-management.svg';
import locationManagementIcon from '../../../assets/logo/assets/location-management.svg';

// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 330;
export const DRAWER_WIDTH_MINI = 86;

const RootStyle = styled('div')(({ theme }) => ({
  background: '#1C2536',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

interface AccountStyleProps {
  theme?: Theme;
}

const AccountStyle = styled('div')((props: AccountStyleProps) => ({
  display: 'flex',
  alignItems: 'center',
  padding: props.theme?.spacing(2, 2.5),
  borderRadius: props.theme?.shape.borderRadius,
  backgroundColor: props.theme?.palette.grey[200],
}));

// ----------------------------------------------------------------------

interface Props {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

export default function DashboardSidebar(props: Props) {
  const { isOpenSidebar, onCloseSidebar } = props;
  const { pathname } = useLocation();
  // const fetchFormData = useSelector(selectAuth);
  // const { userInformation } = fetchFormData;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const getIcon = (name: string) => (
    <img src={name} alt="" style={{ color: 'white' }} />
  );

  const sidebarConfig: GPSidebarMenu[] = useMemo(
    () => [
      {
        title: 'Manage Asset',
        path: path.assets,
        icon: getIcon(assetManagementIcon),
        isShow: true,
        children: [
          {
            title: 'Config Data',
            path: path.assetManagement,
          },
        ],
      },
      {
        title: 'Content',
        path: path.content,
        icon: getIcon(contentManagementIcon),
        isShow: true,
      },
      {
        title: 'Location',
        path: path.location,
        icon: getIcon(locationManagementIcon),
        isShow: true,
      },
    ],
    [],
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', flexDirection: 'row' }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{ display: 'inline-flex', textDecoration: 'none' }}
        >
          <img src={LogoArMuseum} alt={'Ar Museum'} />
          {/* <Logo /> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // color: "#7A7A7A",
            fontStyle: 'normal',
            textTransform: 'uppercase',
            marginLeft: '14px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '17px',
              lineHeight: '24px',
            }}
          >
            3D Art In Paradise
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              fontSize: '17px !important',
              lineHeight: '24px',
            }}
          >
            Da Nang
          </Typography>
        </Box>
      </Box>
      <Box sx={{ px: 2.5 }}>
        <Typography
          sx={{ color: '#9DA4AE', fontSize: '12px', fontWeight: 700 }}
        >
          Settings
        </Typography>
      </Box>
      <NavSection navConfig={sidebarConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, background: '#FFFFFF' },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#1C2536',
              color: '#FFFFFF',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
