import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import ButtonControlled from 'app/components/Button';
import CustomizedInputsStyled from 'app/components/BootstrapInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import UploadFile from 'app/components/UploadFile';
import { Box, FormHelperText } from '@mui/material';
import { EllipsisText } from 'app/components/EllipsisText';
import fileService from 'services/api/fileService';
import toast from 'react-hot-toast';
import { lettersAndNumbersRegex } from 'utils/helpers/regex';
import { LoadingButton } from '@mui/lab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  padding: 5,
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogType {
  open: boolean;
  setOpen: (v: boolean) => void;
  onSubmit: (values: any) => void;
  loading: boolean;
}

export default function CreateAsset(props: DialogType) {
  const { open, setOpen, onSubmit, loading } = props;
  const acceptFile = ['application/x-zip-compressed'];
  // const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    return () => reset();
  }, []);

  const authSchema = Yup.object()
    .shape({
      // name: Yup.string().required('Name cannot be blank').matches(lettersAndNumbersRegex, "Names must only use letters (abc,ABC) and numbers (0-9)."),
      // image: Yup.mixed().required("image cannot be blank"),
      bundle_ios: Yup.mixed().required('Please upload an asset.'),
      bundle_android: Yup.mixed().required('Please upload an asset.'),
    })
    .required();

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    reset,
  } = useForm<any>({ resolver: yupResolver(authSchema), mode: 'onChange' });

  const handleClose = () => {
    setOpen(false);
  };

  const validateFile = (file: File, field: any) => {
    // console.log(file, 'file')
    if (!acceptFile.includes(file.type)) {
      setError(field.name, {
        message: 'This field invalid.',
      });
      return 'This field invalid.';
    }
  };

  const handleFileCallback = async (file: File, field: any) => {
    clearErrors();
    field.onChange(file);
    // const formData = new FormData();
    // formData.append('file', file);
    // try {
    //   const response = await fileService.uploadFile(formData);
    //   field.onChange(response.data);
    // } catch (error) {
    //   toast.error(
    //     'Upload file eror',
    //     {
    //       duration: 2000,
    //     },
    //   );
    // }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BootstrapDialog
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              padding: '41px 48px',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: 'center',
            color: '#2F3746',
            fontSize: '16px',
          }}
          id="customized-dialog-title"
        >
          Add Asset
        </DialogTitle>
        <DialogContent sx={{ color: '#6C737F', fontSize: '13px', padding: 0 }}>
          {/* <Controller
            name={"name"}
            shouldUnregister={true}
            control={control}
            render={({ field }) => {
              return (
                <CustomizedInputsStyled
                  placeholder="name"
                  handleChange={e => field.onChange(e)}
                  rows={2}
                  multiline
                  helperText={errors?.name?.message as string}
                />
              )
            }}
          /> */}
          {/* <Typography sx={{ fontSize: "12px", color: "#6C737F", fontWeight: 600, mt: 2 }}>Image</Typography>
          <Controller
            name={"image"}
            control={control}
            shouldUnregister={true}
            render={({ field }) => {
              return (
                <Box>
                  <Box mt={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{
                      borderRadius: "4px", padding: "0 12px", display: "flex", justifyContent: "center", alignItems: "center",
                      background: "rgba(217, 217, 217, 0.20)"
                    }}>
                      {field?.value?.name ? <EllipsisText maxWidth={200} line={1} text={field.value.name} /> : '-'}
                    </Box>
                    <UploadFile
                      field={field}
                      acceptFile="image/png, image/jpeg"
                      validateFile={validateFile}
                      handleFileCallback={handleFileCallback}
                    />
                  </Box>
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: 10,
                    }}
                    error
                  >
                    {errors?.image?.message as string}
                  </FormHelperText>
                </Box>
              )
            }}
          />
          <Typography sx={{ fontSize: "12px", color: "#6C737F", fontWeight: 600, mt: 2, mb: 1 }}>Image Width</Typography>
          <Controller
            name={"physical_width"}
            control={control}
            shouldUnregister={true}
            render={({ field }) => {
              return (
                <CustomizedInputsStyled
                  placeholder="Image Width"
                  handleChange={e => field.onChange(e)}
                  helperText={errors?.physical_width?.message as string}
                />
              )
            }}
          /> */}
          <Typography
            sx={{ fontSize: '12px', color: '#6C737F', fontWeight: 600, mt: 2 }}
          >
            Asset IOS
          </Typography>
          <Controller
            name={'bundle_ios'}
            control={control}
            shouldUnregister={true}
            render={({ field }) => {
              return (
                <Box>
                  <Box mt={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                      sx={{
                        borderRadius: '4px',
                        padding: '0 12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(217, 217, 217, 0.20)',
                      }}
                    >
                      {field?.value?.name ? (
                        <EllipsisText
                          maxWidth={200}
                          line={1}
                          text={field.value.name}
                        />
                      ) : (
                        '-'
                      )}
                    </Box>
                    <UploadFile
                      field={field}
                      acceptFile=".zip"
                      validateFile={validateFile}
                      handleFileCallback={handleFileCallback}
                    />
                  </Box>
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: 10,
                    }}
                    error
                  >
                    {errors?.bundle_ios?.message as string}
                  </FormHelperText>
                </Box>
              );
            }}
          />
          <Typography
            sx={{ fontSize: '12px', color: '#6C737F', fontWeight: 600, mt: 2 }}
          >
            Asset Android
          </Typography>
          <Controller
            name={'bundle_android'}
            control={control}
            shouldUnregister={true}
            render={({ field }) => {
              return (
                <Box>
                  <Box mt={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                      sx={{
                        borderRadius: '4px',
                        padding: '0 12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(217, 217, 217, 0.20)',
                      }}
                    >
                      {field?.value?.name ? (
                        <EllipsisText
                          maxWidth={200}
                          line={1}
                          text={field.value.name}
                        />
                      ) : (
                        '-'
                      )}
                    </Box>
                    <UploadFile
                      field={field}
                      acceptFile=".zip"
                      validateFile={validateFile}
                      handleFileCallback={handleFileCallback}
                    />
                  </Box>
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: 10,
                    }}
                    error
                  >
                    {errors?.bundle_android?.message as string}
                  </FormHelperText>
                </Box>
              );
            }}
          />
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            mt: 5,
          }}
        >
          <ButtonControlled
            title="CANCEL"
            sxProps={{
              borderRadius: '10px',
              border: '1px solid #FF66A3',
              color: '#FF66A3',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
            }}
            handleClick={handleClose}
          />
          <LoadingButton
            color="secondary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            // loadingPosition="start"
            sx={{
              borderRadius: '10px',
              background: '#FF66A3',
              color: '#FFF',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
              '&:hover': {
                background: '#FF66A3',
              },
            }}
            // startIcon={<SaveIcon />}
            variant="contained"
          >
            SAVE
          </LoadingButton>
          {/* <ButtonControlled
            title="SAVE"
            typeButton={"submit"}
            handleClick={handleSubmit(onSubmit)}
            sxProps={{
              borderRadius: '10px',
              background: '#FF66A3',
              color: '#FFF',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
            }}
          /> */}
        </DialogActions>
      </BootstrapDialog>
    </form>
  );
}
