import { Icon } from '@iconify/react';
import { Box, Typography } from '@mui/material';
import ButtonControlled from 'app/components/Button';
import { EllipsisText } from 'app/components/EllipsisText';
import CustomizedDialogs from 'app/components/Modal';
import React from 'react';
import { FilePatch, Files } from 'types/Assets';

export const RenderUploadFile = ({
  label,
  fileName,
  handleUpload,
}: {
  label: string;
  fileName: string;
  handleUpload: (f: FilePatch) => void;
}) => {
  const [dialog, setDialog] = React.useState<boolean>(false);

  return (
    <Box ml={2}>
      <Box>
        <Typography
          sx={{
            color: '#6C737F',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          {label}
        </Typography>
        <Box mt={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mt={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                borderRadius: '4px',
                background: 'rgba(217, 217, 217, 0.20)',
                maxWidth: 'max-content',
                height: 'max-content',
                padding: '4px 8px',
                lineHeight: '21px',
                color: '#111927',
                fontSize: '13px',
                fontWeight: 600,
                marginRight: 2,
              }}
            >
              {<EllipsisText text={fileName} line={1} maxWidth={220} />}
            </Box>
            {/* <ButtonControlled
                title="Import"
                handleClick={() => setDialog(true)}
                iconNode={
                  <Icon icon="material-symbols:upload" color="black" />
                }
              /> */}
          </Box>
        </Box>
      </Box>
      <CustomizedDialogs
        open={dialog}
        setOpen={() => setDialog(false)}
        handleUpload={handleUpload}
      />
    </Box>
  );
};
