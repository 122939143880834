import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

declare module '@mui/material' {
  interface Color {
    0: string;
    100_7: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
    500_85: string;
    500_90: string;
    100_3: string;
    100_4: string;
    100_5: string;
  }
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  50: '#F4F4F4',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  100_7: alpha('#ABABAB', 0.7),
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
  500_85: alpha('#FF324F', 0.8),
  500_90: alpha('#1DE9B6', 0.8),
  100_3: '#A5F6E2',
  100_4: '#F18A99',
  100_5: '#FFCD29',
};

const PRIMARY = {
  lighter: '#E2E2E2',
  light: '#F4F4F4',
  main: '#585858',
  dark: '#e7e7e7',
  darker: '#005249',
  contrastText: '#7cf28b',
};
const SECONDARY = {
  lighter: '#FFFFFF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#F8BC20',
  darker: '#c7ac00',
  contrastText: '#585858',
};
const INFO = {
  lighter: '#49A299',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#585858',
  darker: '#1DE9B6',
  contrastText: '#49A299',
};
const SUCCESS = {
  lighter: '#ABABAB',
  light: '#1DE9B6',
  main: '#39C24F',
  dark: '#1DE9B6',
  darker: '#F7F7F7',
  contrastText: '#D9D9D9',
};
const WARNING = {
  lighter: '#8BCDF1',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#1DA0E9',
  darker: '#f3f3f3',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#DA5264',
  main: '#FF0000',
  dark: 'rgba(255, 205, 41, 0.5)',
  darker: 'rgba(29, 233, 182, 0.5)',
  contrastText: '#FF324F',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY as any,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: PRIMARY.main, secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
