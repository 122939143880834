import * as React from 'react';
import { useState, useCallback } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from 'utils/helpers/regex';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeycloakError, AuthParams } from 'types';
// ----------------------------------------------------------------------
interface Props {
  onSubmit: (values: AuthParams) => void;
  error?: string;
  setError?: (v: string) => void;
}

const authSchema = Yup.object()
  .shape({
    email: Yup.string()
      .required('You must enter a valid email')
      .matches(EMAIL_REGEX, 'Incorrect email format'),
    password: Yup.string().required('You must enter a valid password'),
  })
  .required();

export default function LoginForm(props: Props) {
  const { onSubmit, error, setError } = props;
  const [showPassword, setShowPassword] = useState(false);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<AuthParams>({ resolver: yupResolver(authSchema) });

  const handleShowPassword = useCallback(() => {
    setShowPassword(show => !show);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={1} mb={2} spacing={3}>
        <Box>
          <Typography
            sx={{
              color: '#FF66A3',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
            }}
          >
            Email
          </Typography>
          <Box
            mt={1}
            sx={{ width: '36px', height: '2px', background: '#FF66A3' }}
          ></Box>
        </Box>
        <TextField
          {...register('email')}
          fullWidth
          label={`Email Address`}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          {...register('password')}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          label={`Password`}
          error={!!errors.password}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? 'mdi:eye' : 'mdi:eye-off'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {error && (
        <Typography
          sx={{
            color: '#F04438',
            fontSize: '12px',
          }}
        >
          {error}
        </Typography>
      )}
      <LoadingButton
        sx={{
          background: '#FF66A3',
          borderRadius: '12px',
          marginTop: 2,
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {`Continue`}
      </LoadingButton>
    </form>
  );
}
