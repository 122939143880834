import React from 'react';

// material
import {
  Box,
  Grid,
  TableContainer,
  Typography,
  Table as MuiTable,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import Scrollbar from 'app/components/Scrollbar';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { isEmpty, isUndefined } from 'lodash';
import {
  AssetManagementParams,
  AssetManagementProtype,
  AssetManagementResponse,
  AssetPayload,
  FileAssetParams,
  FilePatch,
} from 'types/Assets';
import { useAuth } from 'app/hooks/useAuth';

import assetService from 'services/api/assetService';

import { Icon } from '@iconify/react';
import ButtonControlled from 'app/components/Button';
// import CustomizedInputsStyled from 'app/components/BootstrapInput';

import CreateAsset from './components/Create';
import RemoveAsset from './components/Remove';
import { RenderItemTextfield } from './components/RenderTextField';
import { RenderUploadFile } from './components/RenderUploadFile';
import { RenderHeaderInput } from './components/RenderHeaderInput';

const RootContainer = styled(Grid)`
  .MuiTableCell-head {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #868686;
    white-space: nowrap;
    padding: 16px;
  }
`;

interface Paginate {
  total_page: number;
  total_records: number;
}

export default function AssetManagement() {
  const ref: any = React.useRef(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [height, setHeight] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [paginate, setPaginate] = React.useState<Paginate>({
    total_page: 0,
    total_records: 0,
  });
  const [dialog, setDialog] = React.useState<any>({
    type: '',
    isOpen: false,
    data: null,
  });
  const [data, setData] = React.useState<AssetManagementProtype[]>([]);
  // console.log(data, 'data')
  const auth = useAuth();
  const { user } = auth;

  React.useLayoutEffect(() => {
    setHeight(ref?.current?.offsetHeight || 0);
  }, []);

  React.useEffect(() => {
    const params: AssetManagementParams = {
      page: 1,
      size: 5,
    };
    fetchData(params).catch((error: any) => {
      toast.error(`${error.response.data.error}`, {
        duration: 2000,
      });
    });
  }, []);

  const fetchData = async (params: AssetManagementParams) => {
    const response: any = await assetService.list(params);
    const assets: any[] = response.data;
    setPaginate({
      total_page: response.paginate.total_page,
      total_records: response.paginate.total_records,
    });
    setData(assets);
  };

  const handleSelectRow = (rowData: any) => {};

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateValueAsset = (uid: string, _obj: any) => {
    const newArr: AssetManagementProtype[] = data.map(
      (obj: AssetManagementProtype) => {
        if (obj.id === uid) {
          return { ...obj, ..._obj };
        }

        return obj;
      },
    );
    return newArr;
  };

  const handleClickEitNameAsset = (uid: string, data: any) => {
    const newData: AssetManagementProtype[] = updateValueAsset(uid, data);
    setData(newData);
  };

  const handleClickCancelEditNameAsset = (uid: string, _obj: any) => {
    const newData: AssetManagementProtype[] = updateValueAsset(uid, _obj);
    setData(newData);
  };

  const handleClickConfirmEditNameAsset = (uid: string, _obj: any) => {
    const newData: AssetManagementProtype[] = updateValueAsset(uid, _obj);
    setData(newData);
  };

  const handleClose = () => {
    setDialog({
      type: '',
      isOpen: false,
      data: null,
    });
  };

  const handleRemoveAsset = async () => {
    try {
      await assetService.removeAsset(dialog.data);
      setDialog({
        type: '',
        isOpen: false,
        data: null,
      });
      const params: AssetManagementParams = {
        page: 1,
        size: rowsPerPage,
      };
      fetchData(params).catch((error: any) => {
        toast.error(`${error.response.data.error}`, {
          duration: 2000,
        });
      });
      toast.success('Remove asset successfully', {
        duration: 2000,
      });
    } catch (error) {
      toast.error('Remove asset error.', {
        duration: 2000,
      });
    }
  };

  const handleChangeTitle = async (
    uid: string,
    value: string,
    field: string,
  ) => {
    const formData = new FormData();
    formData.append(`${field}`, value.trim());
    try {
      await assetService.edit(uid, formData);
      setDialog({
        type: '',
        isOpen: false,
        data: null,
      });
      const params: AssetManagementParams = {
        page: 1,
        size: rowsPerPage,
      };
      fetchData(params).catch((error: any) => {
        toast.error(`${error.response.data.error}`, {
          duration: 2000,
        });
      });
      toast.success('Edit asset name successfully.', {
        duration: 2000,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Change unsuccessfully!.', {
        duration: 2000,
      });
    }
  };

  const handleUpload = async (
    file: FilePatch,
    data: AssetManagementProtype,
    field: string,
  ) => {
    const payload: FileAssetParams = {
      fileId: data.files.filter(item => item.field === field)[0].id,
      new_path: file.path,
      name: file.name,
      old_path: data.files.filter(item => item.field === field)[0].path,
    };
    try {
      await assetService.editFile(data.id, { file: payload });
      setDialog({
        type: '',
        isOpen: false,
        data: null,
      });
      const params: AssetManagementParams = {
        page: 1,
        size: rowsPerPage,
      };
      fetchData(params).catch((error: any) => {
        toast.error(`${error.response.data.error}`, {
          duration: 2000,
        });
      });
      toast.success('Update asset success', {
        duration: 2000,
      });
    } catch (error) {
      toast.error('Update asset error.', {
        duration: 2000,
      });
    }
  };

  const renderItem = (item: AssetManagementProtype, index?: number) => {
    return [
      <Box key={item.id}>
        <RenderHeaderInput
          item={item}
          handleChange={handleChangeTitle}
          handleRemove={() =>
            setDialog({ type: 'REMOVE', isOpen: true, data: item.id })
          }
        />
        <Box
          mt={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {/* <RenderUploadFile 
              label="Image" 
              item={item.files.filter(item => item.field === 'image')[0]} 
              handleUpload={(file: FilePatch) => handleUpload(file, item, 'image')}
            /> */}
            {/* <RenderItemTextfield label="Image Width" item={item} handleChange={handleChangeTitle}/> */}
            <RenderUploadFile
              label="Asset IOS"
              fileName={item.bundle_ios}
              handleUpload={(file: FilePatch) =>
                handleUpload(file, item, 'bundle_ios')
              }
            />
            <RenderUploadFile
              label="Asset Android"
              fileName={item.bundle_android}
              handleUpload={(file: FilePatch) =>
                handleUpload(file, item, 'bundle_android')
              }
            />
          </Box>
          {/* <Box>
            <Icon style={{ cursor: "pointer", color: "#ff66a3" }} icon="clarity:remove-line" onClick={() => setDialog({ type: "REMOVE", isOpen: true, data: item.id })} />
          </Box> */}
        </Box>
      </Box>,
    ];
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const params: AssetManagementParams = {
      page: newPage + 1,
      size: rowsPerPage,
    };
    fetchData(params).catch((error: any) => {
      toast.error(`${error.response.data.error}`, {
        duration: 2000,
      });
    });
    setPage(newPage);
  };

  const onSubmit = async (data: AssetPayload) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('bundle_android', data.bundle_android);
      formData.append('bundle_ios', data.bundle_ios);
      const res = await assetService.createAsset(data);
      if (res.status === 200) {
        setDialog({
          type: '',
          isOpen: false,
          data: null,
        });
        const params: AssetManagementParams = {
          page: 1,
          size: rowsPerPage,
        };
        fetchData(params).catch((error: any) => {
          toast.error(`${error.response.data.error}`, {
            duration: 2000,
          });
        });
        setLoading(false);
        toast.success('Add asset successfully.', {
          duration: 2000,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error('An error occurred when add asset. Please try again later.', {
        duration: 2000,
      });
    }
  };

  return (
    <Box ref={ref} sx={{ padding: '0px 64px' }}>
      <Typography
        variant="h3"
        sx={{
          color: '#111927',
          fontSize: '33px',
        }}
      >
        Asset
      </Typography>
      <Box
        mt={3}
        sx={{
          borderRadius: '20px',
          boxShadow: '0px 5px 22px 0px rgba(0, 0, 0, 0.04)',
        }}
      >
        <Box
          sx={{
            padding: '11px 24px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: 'max-content' }}>
            <Typography
              sx={{
                color: '#FF66A3',
                fontSize: '14px',
              }}
            >
              Effects
            </Typography>
            <Box
              mt={2}
              sx={{
                background: '#FF66A3',
                height: '2px',
              }}
            ></Box>
          </Box>
          <Box>
            <ButtonControlled
              title="Add Asset"
              handleClick={() =>
                setDialog({ ...dialog, type: 'CREATE', isOpen: true })
              }
              iconNode={<Icon icon="zondicons:add-outline" color="black" />}
              sxProps={{
                fontSize: '13px',
                color: '#111927',
              }}
            />
          </Box>
        </Box>
        <Box sx={{ background: '#F8F9FA', padding: '24px' }}>
          {/* <Typography
            sx={{
              color: '#2F3746',
              fontSize: '12px',
              fontWeight: 600,
            }}
          >
            Name
          </Typography> */}
        </Box>
        <RootContainer>
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 800,
                maxHeight: 520,
              }}
            >
              <MuiTable stickyHeader={true}>
                <TableBody>
                  {' '}
                  {isEmpty(data) ? (
                    <TableRow hover>
                      <TableCell align="center" colSpan={5}>
                        {isUndefined(data) ? (
                          <CircularProgress color="primary" />
                        ) : (
                          <Typography>{`No data!!!`}</Typography>
                        )}{' '}
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((item, index) => (
                      <TableRow
                        key={index}
                        onClick={event => handleSelectRow(item)}
                        hover
                      >
                        {renderItem(item, index).map((col, index) => (
                          <TableCell key={index}>
                            <div className={index === 0 ? 'col-table' : ''}>
                              {col}{' '}
                            </div>
                          </TableCell>
                        ))}{' '}
                      </TableRow>
                    ))
                  )}{' '}
                </TableBody>
              </MuiTable>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[4, 8, 12, 16, 20]}
            component="div"
            count={paginate.total_records}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </RootContainer>
        {dialog.type === 'CREATE' && (
          <CreateAsset
            open={dialog.isOpen}
            setOpen={handleClose}
            onSubmit={onSubmit}
            loading={loading}
          />
        )}
        {dialog.type === 'REMOVE' && (
          <RemoveAsset
            open={dialog.isOpen}
            setOpen={handleClose}
            handleSubmit={handleRemoveAsset}
          />
        )}
      </Box>
    </Box>
  );
}
