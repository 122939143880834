import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Container, Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { withLoading } from 'app/components/HOC/WithLoading';
import { useLoading } from 'app/hooks';
import BACkGROUND from 'assets/images/background_login.png';
import toast from 'react-hot-toast';
import { AuthContext } from 'app/context/AuthContext';

import { AuthParams, AuthResponse } from 'types';

import keycloakService from 'services/api/keycloakService';
import { LocalStorageService } from 'services';

import path from 'app/routes/path';

import Page from '../../components/Page';

import LoginForm from './components/Login';
import HeaderLogin from './components/HeaderLogin';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 12),
  background: '#FFFFFF',
}));

// ----------------------------------------------------------------------
interface Props {
  setLoading?: () => void;
}

const AuthPage = (props: Props) => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading(props);
  const [error, setError] = React.useState<string>();
  const { setUser } = React.useContext(AuthContext);

  React.useEffect(() => {
    const timer1 = setTimeout(() => setError(''), 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const handleLogin = async ({ email, password }: AuthParams) => {
    showLoading();
    try {
      const response: AuthResponse = await keycloakService.login({
        email,
        password,
      });
      setUser(response.user);
      LocalStorageService.set(
        LocalStorageService.OAUTH_TOKEN,
        response.access_token,
      );
      LocalStorageService.set(
        LocalStorageService.REFRESH_TOKEN,
        response.refresh_token,
      );
      LocalStorageService.set(LocalStorageService.USER, response.user);
      toast.success('Login successfully.', {
        duration: 2000,
      });
      navigate(path.assetManagement);
    } catch (error: any) {
      hideLoading();
      setError(error?.response?.data?.error);
      // toast.error(
      //   `${error.response.data.error}`,
      //   {
      //     duration: 2000,
      //   },
      // );
    }
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: `url(${BACkGROUND});`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Grid item xs={6} md={6}>
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <HeaderLogin />
              <Box
                sx={{
                  height: '2px',
                  background: '#7A7A7A',
                  width: '100%',
                  margin: '24px 0px',
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: '32px',
                  color: '#111927',
                  fontWeight: 700,
                }}
              >
                LOGIN
              </Typography>
              <LoginForm
                onSubmit={handleLogin}
                error={error}
                setError={setError}
              />
            </Stack>
          </ContentStyle>
        </Grid>
        <Grid item xs={6} md={6}>
          {/* <img src={BACkGROUND} alt="Background Login Ar Museum" loading="lazy" /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default withLoading(AuthPage);
