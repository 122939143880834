import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { useProfile } from 'app/hooks/useProfile';
import { useSafeState } from 'app/hooks/useSafeState';
import path from 'app/routes/path';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalStorageService } from 'services';

import Default_avatar from 'assets/images/default-avatar.jpg';

import MenuPopover from '../MenuPopover';

export default function AccountPopover() {
  const user = useProfile();
  const [open, setOpen] = useSafeState<boolean>(false);
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const handleLogout = () => {
    LocalStorageService.removeAllItem();
    navigate(path.login);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          padding: 0,
          '&:hover': {
            background: '#FFF',
          },
        }}
        ref={anchorRef}
        onClick={handleOpen}
      >
        <Avatar src={Default_avatar} alt="photoURL" />
      </Button>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 275 }}
      >
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleLogout}
          >
            <Icon icon="ion:log-out-outline" width="20px" height="20px" />
            <Typography
              sx={{
                ml: 1,
              }}
              variant="body2"
            >
              {`Sign out`}
            </Typography>
          </Box>
        </Box>
      </MenuPopover>
    </>
  );
}
