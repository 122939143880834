import { LocationPayload } from 'types/Location';

import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL;
const instances = createService(baseURL);

const get = async (): Promise<any> => {
  const response = await instances.get(`/location/list`);
  return response.data;
};

const create = async (data: LocationPayload): Promise<any> => {
  const response = await instances.post(`/location/create`, data);
  return response.data;
};

export default { get, create };
