import Button from '@mui/material/Button';
import { SxProps, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  title?: string | React.ReactNode;
  isDisable?: boolean;
  fullWidth?: boolean;
  handleClick?: (e?: any) => void;
  typeButton?: any;
  isIcon?: boolean;
  width?: number;
  iconNode?: React.ReactNode;
  sxProps?: SxProps;
  variant?: 'text' | 'contained' | 'outlined';
}

const ButtonControlled = (props: Props) => {
  const theme = useTheme();
  const {
    isDisable,
    fullWidth,
    title,
    handleClick,
    typeButton,
    isIcon,
    iconNode,
    sxProps,
    variant,
  } = props;

  return (
    <Button
      disabled={isDisable || false}
      type={typeButton}
      fullWidth={fullWidth}
      onClick={handleClick && handleClick}
      sx={{
        color: '#111927',
        ...sxProps,
        fontSize: '16px',
        fontStyle: 'normal',
        // fontWeight: 400,
        lineHeight: '22.75px',
      }}
    >
      {iconNode && iconNode}
      <span style={{ marginLeft: iconNode ? '8px' : '0px' }}>{title}</span>
    </Button>
  );
};

export default ButtonControlled;
