/* eslint-disable import/no-anonymous-default-export */
import { AuthResponse, AuthParams } from 'types';
import { UserDataType } from 'types/Auth';

import { createService, createServiceNoToken } from './axios';

const baseURL = process.env.REACT_APP_API_URL;

const instance = createServiceNoToken(baseURL);
const instances = createService(baseURL);

const login = async (params: AuthParams): Promise<AuthResponse> => {
  const response = await instance.post('/auth/login', JSON.stringify(params), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const getProfile = async (): Promise<UserDataType> => {
  const response = await instances.get(`/auth/me`);
  return response.data;
};

export default { login, getProfile };
