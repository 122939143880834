import { Box } from '@mui/material';
import ButtonControlled from 'app/components/Button';
import React, { useEffect, useState, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import contentService from 'services/api/contentService';
import { ContentPayload } from 'types/Content';
import toast from 'react-hot-toast';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import DOMPurify from 'dompurify';
import { Icon } from '@iconify/react';
import { convertToHTML } from 'draft-convert';

import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';

interface DataProps {
  content: string;
  id?: string;
  defaultValue: string;
}

const Content = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DataProps>({
    content: '',
    id: '',
    defaultValue: '',
  });
  const editor = useRef(null);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [content, setContent] = useState('');
  // console.log(editorState, 'editorState')

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response: any = await contentService.getContent();
    const res: any[] = response.data;
    if (res.length > 0) {
      setData({
        content: res[0].content,
        id: res[0].id,
        defaultValue: res[0].content,
      });
    }
  };

  const handleSubmit = async () => {
    const payloads: ContentPayload = {
      content: data.content,
    };
    if (data.id) {
      payloads.uuid = data.id;
    }
    try {
      const res = await contentService.create(payloads);
      if (res.status === 200) {
        toast.success('Create content successfully', {
          duration: 2000,
        });

        setData({
          content: data.content,
          id: payloads.uuid,
          defaultValue: payloads.content,
        });
      }
    } catch (error) {
      toast.error('Create content error.', {
        duration: 2000,
      });
    }
  };

  const handleCancel = () => {
    // console.log(data, 'data---- cancel')
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data.defaultValue) as any,
        ),
      ),
    );
    setData({ ...data, content: data.defaultValue });
  };

  const handleChange = (v: string) => {
    // console.log(v, '-------v')
    setData({ ...data, content: v });
  };

  return (
    <Box
      sx={{
        padding: '0 64px',
      }}
    >
      <JoditEditor
        // ref={editor}
        value={data.content}
        config={{
          readonly: false,
          toolbar: true,
          spellcheck: true,
          language: 'en',
          // toolbarButtonSize: "medium",
          toolbarAdaptive: false,
          showCharsCounter: false,
          // showWordsCounter: true,
          // showXPathInStatusbar: false,
          // askBeforePasteHTML: true,
          // askBeforePasteFromWord: true,
          buttons: [
            'undo',
            'redo',
            '|',
            'bold',
            'underline',
            'italic',
            // "|",
            // "ul",
            // "ol",
            // "outdent",
            // "indent",
            // "|",
            // "font",
            // "fontsize",
            // "brush",
            // "paragraph",
          ],
          width: '100%',
          height: 500,
        }}
        onBlur={newContent => setData({ ...data, content: newContent })} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => {}}
        // onChange={value => setContent(value)}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
        <ButtonControlled
          title="CANCEL"
          sxProps={{
            borderRadius: '10px',
            border: '1px solid #FF66A3',
            color: '#FF66A3',
            padding: '6px 16px',
            fontWeight: 700,
            fontSize: '16px',
            minWidth: '150px',
            mr: 2,
          }}
          handleClick={handleCancel}
        />
        <LoadingButton
          color="secondary"
          type="submit"
          onClick={handleSubmit}
          loading={loading}
          // loadingPosition="start"
          sx={{
            borderRadius: '10px',
            background: '#FF66A3',
            color: '#FFF',
            padding: '6px 16px',
            fontWeight: 700,
            fontSize: '16px',
            minWidth: '150px',
            '&:hover': {
              background: '#FF66A3',
            },
          }}
          // startIcon={<SaveIcon />}
          variant="contained"
        >
          SAVE
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Content;
