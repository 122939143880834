import * as React from 'react';
import { Stack, TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import toast from 'react-hot-toast';
import fileService from 'services/api/fileService';
import { FilePatch } from 'types/Assets';

import ButtonControlled from '../Button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  padding: 5,
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const acceptFile = ['image/png', 'image/jpeg', 'zip'];
interface DialogType {
  open: boolean;
  setOpen: (v: boolean) => void;
  handleUpload: (f: FilePatch) => void;
}

export default function CustomizedDialogs(props: DialogType) {
  const { open, setOpen, handleUpload } = props;
  const hiddenFileInput: any = React.useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadFile = () => {
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = async (event: any) => {
    const fileUploaded = event.target.files[0];
    if (acceptFile.includes(fileUploaded?.type)) {
      const formData = new FormData();
      formData.append('file', fileUploaded);
      const response = await fileService.uploadFile(formData);
      if (response.status === 200) {
        handleUpload(response.data);
      }
    } else {
      toast.error('change has failed', {
        duration: 2000,
        position: 'bottom-right',
      });
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              padding: '41px 48px',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: 'center',
            color: '#2F3746',
            fontSize: '16px',
          }}
          id="customized-dialog-title"
        >
          Are you sure you want to confirm this action?
        </DialogTitle>
        <DialogContent
          sx={{ color: '#6C737F', fontSize: '13px', textAlign: 'center' }}
        >
          <Typography gutterBottom>
            We only accept image formats .PNG and .JPEG.
          </Typography>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            mt: 5,
          }}
        >
          <ButtonControlled
            title="CANCEL"
            handleClick={handleClose}
            sxProps={{
              borderRadius: '10px',
              background: '#FF66A3',
              color: '#FFF',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
            }}
          />
          <ButtonControlled
            title="OK"
            sxProps={{
              borderRadius: '10px',
              border: '1px solid #FF66A3',
              color: '#FF66A3',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
            }}
            handleClick={handleUploadFile}
          />
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleChange}
            ref={hiddenFileInput}
            style={{ display: 'none' }} // Make the file input element invisible
          />
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
