import { UploadFileRequest, AssetManagementParams } from 'types/Assets';
import { serialize } from 'utils/helpers';

import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL;
const instance = createService(baseURL, 'multipart/form-data');

const uploadFile = async (params: any): Promise<any> => {
  const response = await instance.post(`/minio-client/upload`, params);
  return response.data;
};

export default { uploadFile };
