// material
import { useMemo } from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { useLocation } from 'react-router-dom';

import { DRAWER_WIDTH_MINI } from '../Sidebar';
import AccountPopover from '../AccountPopover';

const DRAWER_WIDTH = 285;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 85;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
  },
}));

interface Props {
  onOpenSidebar: () => void;
  isOpenSidebar?: boolean;
  handleChangeTheme?: (value: any) => void;
}

export default function DashboardNavbar(props: Props) {
  const { onOpenSidebar, isOpenSidebar, handleChangeTheme } = props;
  const location = useLocation();

  const theme = useTheme();

  const titleFollowLocation = {};

  return (
    <AppBar
      sx={{
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        backgroundColor: alpha(theme.palette.background.default, 1),
        background: theme.palette.common.white,
        paddingLeft: {
          xs: isOpenSidebar ? `${DRAWER_WIDTH}px` : `${DRAWER_WIDTH_MINI}px`, //'unset',
        },
      }}
    >
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary' }}
        >
          <Box
            sx={{
              '& .MuiSvgIcon-root': {
                color: theme.palette.common.black,
              },
            }}
          >
            <DehazeIcon color={'primary'} />
          </Box>
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </AppBar>
  );
}
