import {
  UploadFileRequest,
  AssetManagementParams,
  AssetPayload,
} from 'types/Assets';
import { serialize } from 'utils/helpers';

import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL;
const instance = createService(baseURL, 'multipart/form-data');
const instances = createService(baseURL);

const list = async (params: AssetManagementParams): Promise<any> => {
  const response = await instances.get(`/asset/list?${serialize(params)}`);
  return response.data;
};

const edit = async (uid: string, params: any): Promise<any> => {
  const response = await instances.put(`/asset/${uid}`, params);
  return response.data;
};

const uploadFile = async (params: UploadFileRequest): Promise<any> => {
  const response = await instance.post(`/asset/upload-file/${params.uid}`);
  return response.data;
};

const createAsset = async (params: AssetPayload): Promise<any> => {
  const response = await instance.post(`/asset/create`, params);
  return response.data;
};

const removeAsset = async (uid: string): Promise<any> => {
  const response = await instance.delete(`/asset/${uid}`);
  return response.data;
};

const editFile = async (uid: string, params: any): Promise<any> => {
  const response = await instances.put(`/asset/${uid}`, params);
  return response.data;
};

export default { uploadFile, createAsset, removeAsset, list, edit, editFile };
