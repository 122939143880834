import * as React from 'react';
import { Box, Typography } from '@mui/material';
import LOGO from 'assets/logo/logo_xl.svg';

export default function HeaderLogin() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <Box>
        <img src={LOGO} alt="Logo Login Ar Museum" loading="lazy" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          color: '#7A7A7A',
          fontStyle: 'normal',
          textTransform: 'uppercase',
          marginLeft: '14px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '36px',
            lineHeight: '36px',
          }}
        >
          3D Art In Paradise
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: '36px',
            lineHeight: '36px',
          }}
        >
          Da Nang
        </Typography>
      </Box>
    </Box>
  );
}
