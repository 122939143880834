import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
// import ReactHotToast from 'src/@core/styles/libs/react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import ReactHotToast from 'styles/css/react-hot-toast';

import ThemeConfig from 'styles/theme';

import GlobalStyles from 'styles/theme/globalStyles';

import Routes from './routes';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <BrowserRouter>
      <ThemeConfig>
        <Helmet
          titleTemplate="%s | Ar Museum Admin"
          defaultTitle="Ar Museum Admin"
          htmlAttributes={{ lang: 'en' }}
        >
          <meta name="description" content="Ar Museum Admin" />
        </Helmet>
        <AuthProvider>
          <Routes />
        </AuthProvider>

        <ReactHotToast>
          <Toaster
            position="bottom-right"
            toastOptions={{
              className: 'react-hot-toast',
              success: {
                style: {
                  background: '#2E7D32',
                  color: 'rgba(255, 255, 255, 1)',
                },
              },
              error: {
                style: {
                  background: '#D32F2F',
                  color: 'rgba(255, 255, 255, 1)',
                },
              },
            }}
          />
        </ReactHotToast>
        <GlobalStyles />
      </ThemeConfig>
    </BrowserRouter>
  );
}

export default App;
