import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: '#FFF',
    border: '1px solid',
    borderColor: '#E5E7EB',
    fontSize: 16,
    width: '100%',
    padding: '8px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface BootstrapInputProps {
  lable?: string;
  defaultValue?: string;
  placeholder?: string;
  handleChange: (v: React.ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: string | React.ReactNode;
  startAdornment?: string | React.ReactNode;
  multiline?: boolean;
  rows?: number | string;
  helperText?: string;
  name?: string;
  ref?: any;
  value?: string;
}

export default function CustomizedInputsStyled(props: BootstrapInputProps) {
  const {
    lable,
    ref,
    value,
    name,
    rows = 1,
    helperText,
    multiline,
    defaultValue,
    placeholder,
    handleChange,
    endAdornment,
    startAdornment,
  } = props;

  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      {lable && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {lable}
        </InputLabel>
      )}
      <BootstrapInput
        ref={ref}
        defaultValue={defaultValue}
        multiline={multiline}
        rows={rows}
        id="bootstrap-input"
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        autoFocus
        value={value}
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          )
        }
        inputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : (
            ''
          ),
        }}
      />
      {helperText && (
        <FormHelperText
          sx={{
            mt: 1,
            fontSize: 10,
          }}
          error
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
