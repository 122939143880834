import React from 'react';
import { IconButton, Box, FormHelperText, Typography } from '@mui/material';
import { Icon } from '@iconify/react';

interface Props {
  validateFile?: (file: File, field: any, messageError?: string) => void;
  field: any;
  error?: string;
  handleFileCallback?: (file: File, field: any) => void;
  acceptFile?: string;
}

export default function UploadFile(props: Props) {
  const { validateFile, handleFileCallback, acceptFile, field, error } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elm = e.target;
    if (!elm.files) return;
    const { files } = elm;

    const error = validateFile ? validateFile(files[0], field) : null;
    if (error) {
      return;
    }
    // console.log(files[0], '------- file')
    handleFileCallback && handleFileCallback(files[0], field);
  };

  const renderError = React.useMemo(() => {
    if (error) {
      return (
        <FormHelperText
          sx={{
            mt: 1,
            fontSize: 14,
          }}
          error
        >
          {error}
        </FormHelperText>
      );
    }
    return null;
  }, [error]);

  return (
    <Box>
      <IconButton
        sx={{
          color: '#FF66A3',
          padding: '6px 16px',
          fontWeight: 700,
          fontSize: '16px',
        }}
        component="label"
      >
        <input hidden type="file" onChange={handleChange} accept={acceptFile} />
        <Icon icon="material-symbols:upload" color="black" />
        <Typography ml={1} sx={{ color: '#111927', fontSize: '13px' }}>
          Import
        </Typography>
      </IconButton>
      {renderError}
    </Box>
  );
}
