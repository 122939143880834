import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import ButtonControlled from 'app/components/Button';
import { LoadingButton } from '@mui/lab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  padding: 5,
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogType {
  open: boolean;
  setOpen: (v: boolean) => void;
  handleSubmit: () => void;
}

export default function RemoveAsset(props: DialogType) {
  const { open, setOpen, handleSubmit } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            padding: '41px 48px',
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          textAlign: 'center',
          color: '#2F3746',
          fontSize: '16px !important',
        }}
        id="customized-dialog-title"
      >
        Do you want to delete this asset?
      </DialogTitle>
      <DialogContent
        sx={{
          color: '#6C737F',
          fontSize: '13px',
          padding: 0,
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{ fontSize: '13px', color: '#6C737F', fontWeight: 400 }}
        >
          This action cannot be undone.
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          mt: 5,
        }}
      >
        <ButtonControlled
          title="NO"
          sxProps={{
            background: '#FF66A3',
            borderRadius: '10px',
            border: '1px solid #FF66A3',
            color: '#FFF',
            padding: '6px 16px',
            fontWeight: 700,
            fontSize: '16px',
            minWidth: '150px',
            '&:hover': {
              background: '#FF66A3',
            },
          }}
          handleClick={handleClose}
        />
        {/* <LoadingButton
            color="secondary"
            type="submit"
            onClick={handleClose}
            // loading={loading}
            // loadingPosition="start"
            sx={{
              borderRadius: '10px',
              background: '#FF66A3',
              color: '#FFF',
              padding: '6px 16px',
              fontWeight: 700,
              fontSize: '16px',
              minWidth: '150px',
              '&:hover': {
                background: '#FF66A3',
              }
            }}
            // startIcon={<SaveIcon />}
            variant="contained"
          >
            NO
          </LoadingButton> */}
        <ButtonControlled
          title="YES"
          typeButton={'submit'}
          handleClick={handleSubmit}
          sxProps={{
            borderRadius: '10px',
            border: '1px solid #FF66A3',
            background: '#FFF',
            color: '#FF66A3',
            padding: '6px 16px',
            fontWeight: 700,
            fontSize: '16px',
            minWidth: '150px',
          }}
        />
      </DialogActions>
    </BootstrapDialog>
  );
}
