const path = {
  assets: 'assets',
  assetManagement: '/assets/list',
  content: '/content',
  location: '/location',

  login: '/auth/login',
  notFound: '/not-found',
  root: '/',
  all: '*',
};

export default path;
