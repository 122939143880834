import { ContentPayload } from 'types/Content';

import { createService } from './axios';

const baseURL = process.env.REACT_APP_API_URL;
const instances = createService(baseURL);

const getContent = async (): Promise<any> => {
  const response = await instances.get(`/intro/list`);
  return response.data;
};

const create = async (data: ContentPayload): Promise<any> => {
  const response = await instances.post(`/intro/create`, data);
  return response.data;
};

export default { getContent, create };
